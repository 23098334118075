import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react'
import { useRouter } from 'next-intl/client'
import moment from 'moment'

import {
  TFunc,
  IAdditionalInfo,
  IReserveNowResponse,
  ISelectVehiclePayload,
  IStartReservationPayload,
  IStartReservationResponse,
} from '@/types'
import {
  homePath,
  reviewPath,
  registerEvent,
  setStorageValue,
  getStorageValue,
  removeStorageValue,
} from '@/utils'
import { CONFIRMED_KEY, START_KEY } from '@/constants'
import {
  useSelectVehicle,
  useStartReservation,
  useCancelReservation,
} from '@/apis'

import { TStartReservationStorageData } from './ReservationContext'

const DATE_FORMAT = 'YYYY-MM-DD'

interface IReservationSuccessContext {
  onClose: TFunc
  isLoading: boolean
  isCanceled: boolean
  terms: IAdditionalInfo[]
  cancellationStatus?: string
  onModify: (_cb: TFunc) => void
  onCancel: (_cb: TFunc) => void
  confirmed?: IReserveNowResponse
}

export const ReservationSuccessContext = createContext(
  {} as IReservationSuccessContext,
)

export interface IStorageData extends IReserveNowResponse {
  modifying?: boolean
  isCanceled: boolean
}

export const ReservationSuccessContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const router = useRouter()
  const eventRef = useRef(false)
  const [data, setData] = useState<IStorageData>()
  const timerRef = useRef<ReturnType<typeof setTimeout>>()
  const [cancellationStatus, setCancellationStatus] = useState<string>()

  const { mutateAsync: cancelReservation, isPending: isCancelLoading } =
    useCancelReservation()

  const { mutateAsync: selectVehicle, isPending: isVehicleLoading } =
    useSelectVehicle()

  const { mutateAsync: reservationStart, isPending: isStartingReservation } =
    useStartReservation()

  useEffect(() => {
    const data = getStorageValue<IStorageData>(CONFIRMED_KEY)
    if (!data) {
      router.replace(homePath)
    } else {
      setData(data)
    }
  }, [router])

  useEffect(() => {
    if (eventRef.current || !data) return
    if (!data.modifying) {
      eventRef.current = true
      setData({ ...data, modifying: true })
      setStorageValue(CONFIRMED_KEY, { ...data, modifying: true })
      registerEvent('purchase', data)
    }
  }, [data])

  useEffect(() => {
    clearTimeout(timerRef.current)
    return () => {
      timerRef.current = setTimeout(() => {
        removeStorageValue(CONFIRMED_KEY)
        clearTimeout(timerRef.current)
      }, 200)
    }
  }, [])

  const onClose = useCallback(() => {
    setCancellationStatus(undefined)
  }, [])

  const onCancel = useCallback(
    async (cb?: TFunc) => {
      const { reservation } = data || {}
      const { customer, confId } = reservation || {}
      if (!customer || !confId) return
      const response = await cancelReservation({
        surname: customer.surname,
        givenName: customer.givenName,
        reservationNumber: confId.id,
      })
      cb?.()
      if (response.data.data.cancellation_status) {
        setCancellationStatus(response.data.data.cancellation_status)
        registerEvent('refund', data)
      }
      // @ts-ignore
      setData(prev => ({ ...(prev ?? {}), isCanceled: true }))
      setStorageValue(CONFIRMED_KEY, {
        ...data,
        isCanceled: true,
      })
    },
    [data, cancelReservation],
  )

  const onModify = useCallback(
    async (cb?: TFunc) => {
      cb?.()
      if (!data) return
      const { reservation, location_info, email } = data
      const {
        vehicle,
        customer,
        vehRentalCore,
        pricedEquips = [],
        pricedCoverages = [],
      } = reservation || {}

      const startReservationPayload: IStartReservationPayload = {
        ...location_info,
        driverAge: customer.age,
        dropOffLatitude: location_info.dropOffLatitude ?? undefined,
        dropOffLocation: location_info?.dropOffLocation ?? undefined,
        dropOffLongitude: location_info?.dropOffLongitude ?? undefined,
        pickUpTime: moment(vehRentalCore.pickUpDateTime).format('HH:mm'),
        returnTime: moment(vehRentalCore.returnDateTime).format('HH:mm'),
        pickUpDate: moment(vehRentalCore.pickUpDateTime).format(DATE_FORMAT),
        returnDate: moment(vehRentalCore.returnDateTime).format(DATE_FORMAT),
        // @ts-ignore
        differentLocation: location_info.dropOffLocation ? '1' : '0',
        pickUpStationCode: vehRentalCore.pickUpLocation.LocationCode,
        dropOffStationCode: location_info.dropOffLocation
          ? vehRentalCore.returnLocation.LocationCode
          : undefined,
      }

      const {
        vehicleType,
        transmissionType,
        airConditioning,
        vehicleMakeModel,
      } = vehicle || {}
      const { vehicleCategory, size, doorCount } = vehicleType || {}

      const selectVehiclePayload: ISelectVehiclePayload = {
        ...startReservationPayload,
        vehicle: {
          transmissionType,
          sizeInt: size ? +size : undefined,
          code: vehicleMakeModel?.code as string,
          airConditioning: airConditioning === 'true',
          doorCount: doorCount ? +doorCount : undefined,
          categoryInt: vehicleCategory ? +vehicleCategory : undefined,
        },
      }

      const response = await selectVehicle({
        ...selectVehiclePayload,
      })
      const reservationResponse = await reservationStart({
        ...startReservationPayload,
      })
      const { vehicles: vehicleInfo } = response.data.data
      const payload: TStartReservationStorageData = {
        vehicle,
        data: {
          ...(reservationResponse.data.data as IStartReservationResponse),
        },
        isModifying: true,
        startedAt: new Date(),
        // @ts-ignore
        parameters: {
          ...startReservationPayload,
          email,
          lastName: customer.surname,
          firstName: customer.givenName,
          phoneNumber: customer.phone_number,
          reservationNumber: reservation.confId.id,
        },
        isLocationPage: false,
        reference: {
          type: reservation.confId.type,
          id: vehicle.vehicleMakeModel.code,
          dateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
        },
        extras: vehicleInfo.pricedCoverages.length
          ? vehicleInfo.pricedCoverages
          : pricedCoverages || [],
        equipments: vehicleInfo.pricedEquips.length
          ? vehicleInfo.pricedEquips
          : pricedEquips || [],
        selectedExtras: {
          coverage: pricedCoverages
            ?.filter(_v => +_v.charge.amount !== 0)
            .map(_v => ({
              code: _v.code,
              type: _v.coverageType,
              action: 'Replace',
            })),
          equipments: pricedEquips?.map(_v => ({
            type: _v.equipment.equipType,
            quantity: +_v.equipment.quantity,
            action: 'Replace',
          })),
        },
        arrivalDetails: {
          ...(reservation.customer.arrivalDetails || {}),
        },
        unmodifiedData: data,
        fees: reservation?.fees,
        totalCharge: reservation?.totalCharge,
        rateDistance: reservation?.rateDistance,
        vehicleCharges: reservation?.vehicleCharges,
      }
      setStorageValue(START_KEY, payload)
      const timer = setTimeout(() => {
        router.replace(reviewPath)
        clearTimeout(timer)
      }, 200)
    },
    [data, router, selectVehicle, reservationStart],
  )

  const isLoading = useMemo(
    () => isCancelLoading || isVehicleLoading || isStartingReservation,
    [isCancelLoading, isVehicleLoading, isStartingReservation],
  )

  const value = useMemo(
    () => ({
      onClose,
      onCancel,
      onModify,
      isLoading,
      confirmed: data,
      cancellationStatus,
      isCanceled: !!data?.isCanceled,
      terms: data?.reservation?.locationDetails || [],
    }),
    [data, onClose, onCancel, onModify, isLoading, cancellationStatus],
  )
  return (
    <ReservationSuccessContext.Provider value={value}>
      {children}
    </ReservationSuccessContext.Provider>
  )
}
