import React from 'react'
import Script from 'next/script'

interface IMarkupSchemaProps {
  data: string
}

export const MarkupSchema: React.FC<IMarkupSchemaProps> = ({ data }) => {
  return (
    <Script
      id={Date.now().toString()}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}
